@for (link of mobileLinks; track $index; let last = $last) {
  <a
    [routerLink]="link.link"
    (click)="selectNavItem(link.name)"
    [ngClass]="{
      'bottom-0 absolute text-center w-full border-t-2 border-primary-300': last
    }"
    class="text-base text-white capitalize"
  >
    <div class="py-5 pl-4 w-full" [routerLinkActive]="['active-menu-mobile']">
      <i [ngClass]="link.icon || ''" class="text-lg mr-2"></i>
      {{ link.name }}
    </div>
  </a>
}
