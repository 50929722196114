<nav
  class="bg-primary-400 z-[100] w-full fixed top-0 left-0 px-5 md:px-0 flex h-16 items-center after:content-[''] after:border-b-2 after:border-primary-300 after:w-[calc(100%-40px)] after:absolute after:bottom-0 after:left-[20px]"
>
  <span class="md:hidden">
    <button
      (click)="toggle()"
      class="group"
      mat-icon-button
      type="button"
      id="drawer-button"
      data-testid="drawer-button"
    >
      <i
        [ngClass]="{
          'fa-light fa-xmark': isSideNavOpen,
          'fas fa-bars': !isSideNavOpen
        }"
        class="text-2xl text-white group-hover:text-secondary-100"
      ></i>
    </button>
  </span>
  <span
    class="flex-grow md:flex-grow-0 flex justify-center"
    [routerLink]="termsGeneric.Board.Lower.Plural"
    appRightClickMenu
    [link]="'/' + termsGeneric.Board.Lower.Plural"
    [enableRightClick]="!isArchitect"
  >
    <img
      ngSrc="assets/logo/white-icon-white-text.svg"
      alt="Rithm Logo"
      width="300"
      height="85"
      class="w-28 mx-5"
      priority
    />
  </span>
  <ul class="hidden md:block flex-grow h-3/5">
    @for (navItem of navItems; track $index; let index = $index) {
      <li
        [routerLink]="navItem.link"
        [routerLinkActive]="['active']"
        class="hover:bg-primary-300 rounded cursor-pointer flex justify-center px-4 ml-4 items-center h-full list-none float-left"
        appRightClickMenu
        [link]="'/' + navItem.link"
        [enableRightClick]="!isArchitect"
      >
        <a
          class="text-white no-underline hover:no-underline capitalize"
          [id]="'top-nav-button' + index"
          [attr.data-testid]="'top-nav-button' + index"
          >{{ navItem.name }}</a
        >
      </li>
    }
  </ul>

  @if (isArchitect) {
    <a
      mat-icon-button
      class="mr-4 !no-underline"
      id="search-button"
      data-testid="search-button"
      routerLink="/search"
      routerLinkActive="active"
      (click)="closeMobileSidenav()"
    >
      <i
        class="fa-light fa-magnifying-glass text-2xl text-white group-hover:text-secondary-100"
      ></i>
    </a>
  }
  <button
    (click)="toggleNotifications()"
    (clickOutside)="clickedOutside()"
    mat-icon-button
    type="button"
    class="group mr-4"
    id="notification-button"
    data-testid="notification-button"
  >
    <i
      class="fa-light fa-bell text-2xl text-white group-hover:text-secondary-100 relative"
    >
      @if (newNotification) {
        <span class="alert"></span>
      }
    </i>
  </button>
  <button
    [matMenuTriggerFor]="userMenu"
    type="button"
    class="w-10 h-10 hidden md:block bg-white rounded-full mr-6"
    id="profile-button"
    data-testid="profile-button"
  >
    <app-user-avatar
      [firstName]="user.firstName"
      [lastName]="user.lastName"
      [navProfile]="true"
      [size]="'40'"
      [profileImageRithmId]="user.profileImageRithmId || ''"
      (updateProfileImage)="updateProfileImage()"
    />
  </button>
</nav>
<mat-menu #userMenu="matMenu">
  <button
    mat-menu-item
    type="button"
    routerLink="settings/account"
    data-testid="my-account"
    id="my-account"
  >
    My Account
  </button>
  <button
    mat-menu-item
    type="button"
    (click)="signOut()"
    data-testid="sign-out"
    id="sign-out"
  >
    Sign Out
  </button>
</mat-menu>
@if (notificationsVisible) {
  <app-notification-menu-container />
}
@if (pushNotificationsVisible) {
  <app-notification-push-menu-container />
}
