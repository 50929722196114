import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import { UserService } from 'src/app/core/user.service';
import { NavLink } from 'src/models';

/**
 * Component for the main sidenav links on mobile.
 */
@Component({
  selector: 'app-sidenav-links',
  templateUrl: './sidenav-links.component.html',
  styleUrls: ['./sidenav-links.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, NgOptimizedImage],
})
export class SidenavLinksComponent {
  /** Mobile links. */
  @Input() mobileLinks: NavLink[] = [];

  constructor(
    private sidenavDrawerService: SidenavDrawerService,
    private userService: UserService,
  ) {}

  /**
   * Toggles the side nav and determines if the user wants to sign out.
   * @param item The tapped side nav item.
   */
  selectNavItem(item: string): void {
    if (item === 'Sign Out') {
      this.userService.signOut();
    }
    this.sidenavDrawerService.closeSidenav();
  }
}
