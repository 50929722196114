<!-- Toast Notifications -->
<app-notification-toasts-container />

<!-- Top nav -->
@if (showTopNav) {
  <app-top-nav />
}

<mat-sidenav-container
  [ngClass]="{ 'mt-0 h-screen': !showTopNav, 'mt-16 h-calc': showTopNav }"
  hasBackdrop="true"
>
  <!-- Side nav -->
  <mat-sidenav #sidenav mode="over" class="flex flex-col !w-full h-full pt-4">
    @if (showSideNav) {
      <app-sidenav-links [mobileLinks]="mobileLinks" />
    }
  </mat-sidenav>

  <!-- Main page content -->
  <mat-sidenav-content
    class="flex-auto bg-gray-50"
    [ngClass]="{ 'non-entry': showTopNav, dark: darkEnabled }"
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
